<template>
  <div>
    <TiendaTable></TiendaTable>
  </div>
</template>

<script>
import TiendaTable from '@/components/tienda/TiendaTable.vue';
export default {
  components: {
    TiendaTable,
  },
};
</script>
