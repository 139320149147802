<template>
  <vx-card>
    <!-- TABLE ACTION ROW -->
    <div class="flex justify-between items-center">
      <vs-input
        class="mb-4 w-full md:mb-0 mr-4"
        v-model="searchQuery"
        @input="updateSearchQuery"
        placeholder="Buscar..."
      />
      <div>
        <vs-button
          :to="{ name: 'configuracion-crear-capacitacion' }"
          class="mb-4 md:mb-0"
          >Crear</vs-button
        >
      </div>
    </div>
    <ag-grid-vue
      ref="agGridTable"
      :gridOptions="gridOptions"
      class="ag-theme-material w-100 my-4 ag-grid-table"
      :columnDefs="columnDefs"
      :defaultColDef="defaultColDef"
      :rowData="productos"
      rowSelection="multiple"
      colResizeDefault="shift"
      :animateRows="true"
      :floatingFilter="false"
      :pagination="true"
      :paginationPageSize="paginationPageSize"
      :suppressPaginationPanel="true"
      :enableRtl="$vs.rtl"
    >
    </ag-grid-vue>
    <vs-pagination
      :total="totalPages"
      :max="maxPageNumbers"
      v-model="currentPage"
    />
  </vx-card>
</template>

<script>
import { AgGridVue } from 'ag-grid-vue';
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss';
import CellRendererActions from './cell-renderer/CellRendererActions.vue';
export default {
  components: {
    AgGridVue,
    CellRendererActions,
  },
  data() {
    return {
      searchQuery: '',
      gridOptions: {},
      maxPageNumbers: 5,
      gridApi: null,
      defaultColDef: {
        sortable: true,
        editable: false,
        resizable: true,
        suppressMenu: true,
      },
      columnDefs: [
        {
          headerName: 'Nombre',
          field: 'nombre',
          width: 150,
        },
        {
          headerName: 'Categoría',
          field: 'categoria',
          width: 150,
        },
        {
          headerName: 'Valor',
          field: 'valor',
          width: 150,
        },
        {
          headerName: 'Puntos',
          field: 'puntos',
          width: 150,
        },
        {
          headerName: 'Acciones',
          field: 'acciones',
          width: 150,
          cellRendererFramework: 'CellRendererActions',
        },
      ],
      productos: [],
    };
  },
  watch: {
    '$store.state.windowWidth'(val) {
      if (val <= 576) {
        this.maxPageNumbers = 5;
      }
    },
  },
  computed: {
    paginationPageSize() {
      if (this.gridApi) return this.gridApi.paginationGetPageSize();
      else return 50;
    },
    totalPages() {
      if (this.gridApi) return this.gridApi.paginationGetTotalPages();
      else return 0;
    },
    currentPage: {
      get() {
        if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1;
        else return 1;
      },
      set(val) {
        this.gridApi.paginationGoToPage(val - 1);
      },
    },
  },
  methods: {
    updateSearchQuery(val) {
      this.gridApi.setQuickFilter(val);
    },
    fetchProductos() {
      this.$http('/api/productos').then(({ data }) => {
        this.productos = data;
      });
    },
  },
  mounted() {
    this.gridApi = this.gridOptions.api;
    if (this.$store.state.windowWidth > 768) {
      this.gridApi.sizeColumnsToFit();
    }
    this.fetchProductos();
  },
};
</script>
